<template>
  <div>
    <x-header
      typeHeight="350"
      pageType="newsDetail"
      :newsTitleRoute="nowShowNews.title"
    >
      <img slot="headerImage" src="../../assets/news/banner.jpg" alt="" />
    </x-header>
    <div class="new_details">
      <div class="new_details_l">
        <h3>{{$store.state.navShow == true ? '最新新闻':'Latest News'}}</h3>
        <ul>
          <li
            v-for="(item, index) in $store.state.navShow == true ?  xinNews : xinNewsE"
            :key="index"
            data-aos="fade-up"
            :data-aos-delay="Number(index * 100 + 300)"
            @click="XinClick(item.id)"
          >
            {{ item.title }}
          </li>
        </ul>
      </div>
      <!-- 内容 -->
      <div class="new_details_r">
        <div class="newTitle">
          <p>{{ nowShowNews.title }}</p>
          <div class="newTitle_intro">
            <p>
              {{$store.state.navShow == true ?'来源:':'News Source : '}}<span>{{ nowShowNews.origin_name }}</span>
            </p>
            <div class="hrLine"></div>
            <p>
              {{$store.state.navShow == true ?'作者:':'Author : '}}<span>{{ nowShowNews.username }}</span>
            </p>
            <div class="hrLine"></div>
            <p>
              {{$store.state.navShow == true ?'发布时间:':'News Release Time : '}}<span>{{ nowShowNews.inputtime }}</span>
            </p>
            <div class="hrLine"></div>
            <p>
              <span>{{ nowShowNews.hits }}</span
              >{{$store.state.navShow == true ?'次浏览':'Views'}}
            </p>
          </div>
        </div>
        <div class="introduction">
          <p>{{ nowShowNews.introduction }}</p>
        </div>
        <div class="newContent" v-html="nowShowNews.content"></div>

        <div class="newsPrevNext">
          <div
            class="news_prev publicCss"
            @click="prevClick(prevData.id)"
            v-if="prevData !== null"
          >
            <div class="imgKuang">
              <img src="../../assets/news/detailsPrev.png" alt="" />
              <img src="../../assets/news/detailsPrev_active.png" alt="" />
            </div>
            <p>上一篇：{{ prevData.title }}</p>
          </div>
          <div class="news_prev publicCss" v-else style="color: #ccc">
            暂无更多内容
          </div>

          <div
            class="news_next publicCss"
            @click="nextClick(nextData.id)"
            v-if="nextData !== null"
          >
            <p class="nextP">下一篇：{{ nextData.title }}</p>
            <div class="imgKuang">
              <img src="../../assets/news/detailsNext.png" alt="" />
              <img src="../../assets/news/detailsNext_active.png" alt="" />
            </div>
          </div>
          <div class="news_next publicCss" v-else style="color: #ccc">
            暂无更多内容
          </div>
        </div>

        <!-- 相关业务 -->
        <div class="related_yewu">
          <p>{{$store.state.navShow == true ?'相关业务':'Related Business'}}</p>
          <div class="related_yewu_main">
            <div
              class="main_items"
              v-for="(item, index) in $store.state.navShow == true ? relatedArr : relatedArrE"
              :key="index"
              @click="relatedClick(item.jumpPageUrl)"
            >
              <img :src="item.relateImg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import xHeader from '../../components/header.vue'
export default {
  components: {
    xHeader
  },
  data () {
    return {
      // 存放左边最新新闻的数组
      xinNewsE: [],
      xinNews: [
        {
          xinNewsName:
            'MG＋邯郸 | 国际友城恳谈会：MG＋邯郸 | 国际友城恳谈会：MG海外项'
        },
        {
          xinNewsName:
            'MG+冀欧号 |励志笃行，中欧班列7月17日MG+冀欧号 |励志笃行，中欧班列7月17日'
        }
      ],
      // 新闻标题
      newsTitle: 'MG+冀欧号 |励志笃行，中欧班列7月17日准时发行！',
      // 当前展示的新闻信息
      nowShowNews: {},
      // 上一篇
      prevData: {},
      // 下一篇
      nextData: {},

      // 相关业务
      relatedArr: [
        {
          relateImg: require('../../assets/news/details1.png'),
          jumpPageUrl: '/chinaEurope_train' // 中欧运输(中欧班列)页面
        },
        {
          relateImg: require('../../assets/news/details2.png'),
          jumpPageUrl: '/international_sea' // 海运服务(国际服务)页面
        },
        {
          relateImg: require('../../assets/news/details3.png'),
          jumpPageUrl: '/international_car' // 汽车运输(国际汽运)页面
        },
        {
          relateImg: require('../../assets/news/details4.png'),
          jumpPageUrl: '/airServices' // 空运服务
        },
        {
          relateImg: require('../../assets/news/details5.png'),
          jumpPageUrl: '/produetsEQingServices' // 清关服务
        },
        {
          relateImg: require('../../assets/news/details6.png'),
          jumpPageUrl: '/international_trade' // 国际贸易
        },
        {
          relateImg: require('../../assets/news/details7.png'),
          jumpPageUrl: '/businessServices' // 商务服务
        }
      ],
      relatedArrE: [
        {
          relateImg: require('../../assets/news/details1E.png'),
          jumpPageUrl: '/chinaEurope_train' // 中欧运输(中欧班列)页面
        },
        {
          relateImg: require('../../assets/news/details2E.png'),
          jumpPageUrl: '/international_sea' // 海运服务(国际服务)页面
        },
        {
          relateImg: require('../../assets/news/details3E.png'),
          jumpPageUrl: '/international_car' // 汽车运输(国际汽运)页面
        },
        {
          relateImg: require('../../assets/news/details4E.png'),
          jumpPageUrl: '/airServices' // 空运服务
        },
        {
          relateImg: require('../../assets/news/details5E.png'),
          jumpPageUrl: '/produetsEQingServices' // 清关服务
        },
        {
          relateImg: require('../../assets/news/details6E.png'),
          jumpPageUrl: '/international_trade' // 国际贸易
        },
        {
          relateImg: require('../../assets/news/details7E.png'),
          jumpPageUrl: '/businessServices' // 商务服务
        }
      ]
    }
  },

  methods: {
    /**
     * 相关业务点击事件
     * 肖明霞
     * 2021-11-22
     */
    relatedClick (jumpUrl) {
      this.$router.push(jumpUrl)
    },
    /**
     * 新闻详情 接口函数
     * 肖明霞
     * 2021-11-23
     */
    newsDetailsFun (xwid) {
      this.$axios
        .get('/api/Index/newsInfo', {
          params: {
            id: xwid
          }
        })
        .then((res) => {
          console.log('新闻详情=>', res)
          const { data } = res
          if (data.code === 1) {
            this.nowShowNews = data.data.info
            // this.xinNews = data.data.news.map((item) => {
            //   return item
            // })

            // 上一篇
            this.prevData = data.data.prev

            // 下一篇
            this.nextData = data.data.next
          } else {
            this.$message({
              showClose: true,
              message: '新闻详情' + res.data.msg,
              type: 'error'
            })
          }
        })
    },

    /**
     * 上一篇 点击函数
     * 肖明霞
     * 2021-11-23
     */
    prevClick (newID) {
      this.newsDetailsFun(newID)
      document.documentElement.scrollTop = 0
    },
    /**
     * 下一篇 点击接口函数
     * 肖明霞
     * 2021-11-23
     */
    nextClick (newID) {
      this.newsDetailsFun(newID)
      document.documentElement.scrollTop = 0
    },

    /**
     * 左边最新新闻 点击函数
     * 肖明霞
     * 2021-11-23
     */
    XinClick (newId) {
      this.newsDetailsFun(newId)
      document.documentElement.scrollTop = 0
    }
  },
  mounted () {
    const xinwenid = Number(this.$route.query.newId)
    console.log('xinwenid', xinwenid)

    this.$axios.get('/apien/Index/newsList').then((res) => {
      console.log('英文新闻中心', res)
      if (res.data.code === 1) {
        this.xinNewsE = res.data.data.list.map((item) => {
          return item
        })
      } else {
        this.$message({
          showClose: true,
          message: '新闻中心与关于我们,' + res.data.msg,
          type: 'error'
        })
      }
    })

    this.$axios.get('/api/Index/newsList').then((res) => {
      console.log('中文新闻中心', res)
      if (res.data.code === 1) {
        this.xinNews = res.data.data.list.map((item) => {
          return item
        })
        // this.prevData = data.data.prev
        // 下一篇
        // this.nextData = data.data.next
      } else {
        this.$message({
          showClose: true,
          message: '新闻中心与关于我们,' + res.data.msg,
          type: 'error'
        })
      }
    })

    this.newsDetailsFun(xinwenid)
  }
}
</script>
<style lang="less" scoped>
.new_details {
  width: var(--width1200px);
  display: flex;
  // background: pink;
  margin: 101px auto 125px;
  justify-content: space-between;
  .new_details_l {
    width: 300px;
    height: 350px;
    // background: orange;
    text-align: left;
    cursor: pointer;
    & > h3 {
      height: 40px;
      // background: pink;
      font-family: Microsoft YaHei;
      color: #2e67b1;
    }
    & ul {
      & li {
        height: 58px;
        // background: red;
        line-height: 58px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-top: 1px solid #ccc;
        &:last-child {
          border-bottom: 1px solid #ccc;
        }
        &:hover {
          color: #2e67b1;
          // background: #2e67b1;
          font-weight: 600;
        }
      }
    }
  }
  .new_details_r {
    width: 810px;
    padding-top: 9px;
    // background: yellow;
    .newTitle {
      & > p {
        height: 29px;
        font-size: 26px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #2e67b1;
        display: inline-block;
      }
      .newTitle_intro {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px 0 35px 0;
        color: #999;
        font-size: 13px;
        font-family: Microsoft YaHei;
        .hrLine {
          width: 2px;
          height: 14px;
          background: #a1a1a1;
          margin: 0 17px;
        }
      }
    }
    // 引言
    .introduction {
      width: 100%;
      height: 112px;
      background: rgba(249, 249, 249, 0.5);
      border: 1px dashed #2e67b1;
      margin-bottom: 25px;
      padding: 10px 22px 0 30px;
      box-sizing: border-box;
      text-align: left;

      & > p {
        font-size: 13px;
        font-family: Microsoft YaHei;
        color: #2e67b1;
        line-height: 30px;
        text-indent: 32px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3; // 超出1行 出现...
        -webkit-box-orient: vertical;
      }
    }
    // 富文本
    .newContent {
      text-align: left;
      & img {
        max-width: 100%;
      }
    }
    // 上一篇下一篇新闻
    .newsPrevNext {
      width: 100%;
      height: 110px;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      margin: 65px 0 60px 0;
      display: flex;
      justify-content: space-between;
      .publicCss {
        width: 48%;
        display: flex;
        align-items: center;
        // background: gray;
        &:hover > p {
          color: #2e67b1;
          font-weight: 600;
        }
        &:hover img:nth-child(2) {
          z-index: 1;
        }
        & > p {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-left: 17px;
          font-size: 15px;
          font-family: Microsoft YaHei;
          color: #666666;
          cursor: pointer;
        }
        .imgKuang {
          width: 22px;
          height: 22px;
          cursor: pointer;
          // 这中情况会闪动，所以用了下边得方法
          // & > img:nth-child(2){
          //     display: none;
          // }
          //  & > img:hover + img{
          //      display: block;
          //  }
          position: relative;
          // background: orange;
          & > img {
            position: absolute;
            top: 0;
            left: 0;
            &:nth-child(1) {
              z-index: 1;
            }
          }
          &:hover > img:nth-child(2) {
            z-index: 1;
          }
        }
      }
      .news_prev {
        justify-content: flex-start;
        & > p {
          margin-left: 17px;
        }
      }
      .news_next {
        justify-content: flex-end;
        & > p {
          margin-right: 17px;
        }
      }
    }
    // 相关业务
    .related_yewu {
      width: 100%;
      // height: 435px;
      // background: orange;
      text-align: left;
      p {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #2e67b1;
      }
      .related_yewu_main {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        // background: red;
        justify-content: space-between;
        .main_items {
          // flex:1;
          width: 180px;
          height: 180px;
          // background: green;
          margin-top: 28px;
          transition: all 0.15s linear;
          cursor: pointer;
          &:last-child {
            width: 390px;
          }
          & img {
            width: 100%;
            height: 100%;
          }
          &:hover {
            transform: translateY(-15px);
          }
        }
      }
    }
  }
}
</style>
<style lang="less">
.new_details {
  .newContent {
    & img {
      max-width: 100%;
    }
  }
}
</style>
